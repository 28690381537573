import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { environment } from '../../../../environments/environment';

@Component({
  imports: [CommonModule],
  templateUrl: './rl-produtos.component.html',
  styleUrls: ['./rl-produtos.component.scss'],
})
export class ProdutosComponent implements AfterViewInit {
  @ViewChild('frame')
  frame!: ElementRef;

  ngAfterViewInit() {
    const { src } = this.frame.nativeElement;
    const { reporstRefreshTimeout } = environment;

    window.setInterval(() => (this.frame.nativeElement.src = src), reporstRefreshTimeout);
  }
}
